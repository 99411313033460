import { Button, Stack, Typography } from "@mui/material";
import {
  GridColDef,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  GridSortModel,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useRef, useState } from "react";
import ExpandCollapseAllButton from "../../../components/ExpandCollapseAllButton";
import InlineItemsList from "../../../components/InlineItemsList";
import TooltipWrapper from "../../../components/TooltipWrapper";
import TypographyTooltipEllipsis from "../../../components/TypographyTooltipEllipsis";
import DataGrid from "../../../components/grid/DataGrid";
import { convertISODate } from "../../../utilities/dateUtils";
import { nameof } from "../../../utilities/typeHelper";
import { ReportTemplate, ReportTemplateDto } from "../../api/biClient.types";
import { ExpandCollapseButton } from "../reports/ExpandCollapseButton";
import NewLabel from "./NewLabel";
import { formatFundType } from "./helpers/reportTemplateHelper";

interface Props {
  templates: ReportTemplateDto[];
  onTemplateNameClick: (template: ReportTemplate) => void;
  onQuickUse: (template: ReportTemplate) => void;
}

export default function CompanyTemplatesGrid({ templates, onTemplateNameClick, onQuickUse }: Props) {
  const apiRef = useGridApiRef();
  const currentReportRef = useRef<ReportTemplateDto>();
  const [currentReport] = useState<ReportTemplateDto>();
  const sortModelRef = useRef<GridSortModel>();

  const groupColumnDef = useMemo(
    (): GridGroupingColDefOverride => ({
      hideDescendantCount: true,
      headerName: "Name",
      sortable: true,
      aggregable: false,
      hideable: false,
      pinnable: false,
      filterable: false,
      flex: 1,
      sortComparator: (_v1, _v2, row1, row2) => {
        const direction = sortModelRef.current?.at(0)?.sort ?? "asc";
        if (row1.rowNode.type === "group" && row2.rowNode.type === "group") {
          const firstGroupOrder = templates.find((r) => r.groupName === row1.value)?.groupOrder || 0;
          const secondGroupOrder = templates.find((r) => r.groupName === row2.value)?.groupOrder || 0;
          return direction === "asc" ? firstGroupOrder - secondGroupOrder : secondGroupOrder - firstGroupOrder;
        }
        const firstTemplateName = templates.find((r) => r.reportId === row1.rowNode.id)?.name || "";
        const secondTemplateName = templates.find((r) => r.reportId === row2.rowNode.id)?.name || "";
        return firstTemplateName.localeCompare(secondTemplateName);
      },
      renderHeader: (params) => <GroupHeader headerName={params.colDef.headerName} />,
      renderCell: (params: GridRenderCellParams<ReportTemplate, unknown, unknown>) => {
        if (params.rowNode.type === "group") {
          return <GroupName params={params} />;
        }
        return <NameColumn template={params.row} onClick={onTemplateNameClick} />;
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates]
  );
  useEffect(() => {
    currentReportRef.current = currentReport;
  }, [currentReport]);

  const columns = useMemo(
    (): GridColDef<ReportTemplateDto>[] => [
      { field: nameof<ReportTemplateDto>("reportType"), headerName: "Report Type", minWidth: 100 },
      {
        field: nameof<ReportTemplateDto>("fundTypes"),
        headerName: "Fund Type",
        sortable: false,
        minWidth: 140,
        renderCell: (params) => {
          if (params.rowNode.type === "group") {
            return null;
          }
          return !params.row?.fundTypes || params.row?.fundTypes?.length === 0 ? (
            "-"
          ) : (
            <InlineItemsList<string>
              displayCount={1}
              items={params.row.fundTypes.map((f) => formatFundType(f))}
              propGetter={(prop) => prop}
              justify="flex-start"
            />
          );
        },
      },
      {
        field: nameof<ReportTemplateDto>("createdAt"),
        headerName: "Added At",
        valueFormatter: (value) => convertISODate(value),
        renderCell: ({ formattedValue }) => (
          <Typography className="MuiDataGrid-cellContent" color={"secondary"}>
            {formattedValue}
          </Typography>
        ),
      },
      {
        field: "actions",
        sortable: false,
        resizable: false,
        headerName: "",
        cellClassName: "grid-row-actions",
        align: "center",
        minWidth: 105,
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === "group") {
            return null;
          }

          return (
            <Stack>
              <TooltipWrapper title={"Use this template"} placement="bottom">
                <Button variant="text" color="primary" onClick={() => onQuickUse(row)}>
                  Quick Use
                </Button>
              </TooltipWrapper>
            </Stack>
          );
        },
      },
    ],
    [onQuickUse]
  );

  return (
    <DataGrid<ReportTemplateDto>
      apiRef={apiRef}
      treeData
      getTreeDataPath={getTreeDataPath}
      columns={columns}
      rows={templates}
      getRowId={(row) => row.reportId}
      columnHeaderHeight={38}
      hideFooter
      defaultGroupingExpansionDepth={2}
      loading={false}
      groupingColDef={groupColumnDef}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      getRowClassName={(params) => {
        if (apiRef.current.getRowNode(params.id)?.type === "group") {
          return "";
        }
        if (params.row.reportId === currentReportRef.current?.reportId) {
          return "Mui-hovered";
        }
        return "";
      }}
      sortingOrder={["asc", "desc"]}
      onSortModelChange={(model) => (sortModelRef.current = model)}
    />
  );
}

const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row: ReportTemplateDto) => [row.groupName, row.reportId];

function GroupHeader({ headerName }: { headerName: string | undefined }) {
  return (
    <>
      <ExpandCollapseAllButton />
      <Typography variant="subtitle2" sx={{ pl: 1 }}>
        {headerName}
      </Typography>
    </>
  );
}
function GroupName({ params }: { params: GridRenderCellParams }) {
  const apiRef = useGridApiContext();

  if (params.rowNode.type !== "group") {
    return null;
  }

  const expanded = params.rowNode.childrenExpanded === true;

  return (
    <>
      <ExpandCollapseButton
        expanded={expanded}
        onClick={(value) => {
          apiRef.current?.setRowChildrenExpansion(params.id, value);
        }}
      />
      <Typography variant="subtitle2" pl={1}>
        {params.value} ({params.rowNode.children.length})
      </Typography>
    </>
  );
}

function NameColumn({ template, onClick }: { template: ReportTemplate; onClick: (template: ReportTemplate) => void }) {
  return (
    <Typography
      component={"div"}
      noWrap
      className="highlighted-action"
      onClick={() => onClick(template)}
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        color: "text.primary",
        pl: 5.3,
        cursor: "pointer",
        "&:hover": { textDecoration: "underline" },
      }}
    >
      <TypographyTooltipEllipsis text={template.name} />

      {template.isNew && <NewLabel />}
    </Typography>
  );
}

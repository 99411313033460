import { Stack } from "@mui/material";
import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridColumnOrderChangeParams,
  GridFetchRowsParams,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { MutableRefObject } from "react";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { DrillDownRow } from "../../../../api/biApi.types";
import { getBiDataGridStyles } from "../../../../utilities/Utilities";

interface Props {
  columns: GridColDef[];
  rows: DrillDownRow[];
  rowsTotal: number;
  apiRef: MutableRefObject<GridApiPremium>;
  loading: boolean;
  sortModel: GridSortModel;
  selectableRows: boolean;
  onFetchRows: (params: GridFetchRowsParams) => void;
  onSortingChanged: (model: GridSortModel, details: GridCallbackDetails) => void;
  onCellClicked: (params: GridCellParams<DrillDownRow>, event: React.MouseEvent<HTMLElement>) => void;
  onColumnOrderChange: (params: GridColumnOrderChangeParams) => void;
}

// Do not use initialState with sortModel. It will trigger onFetchRows with empty page parameters. https://github.com/mui/mui-x/issues/13994
const DrillDownGrid = ({
  columns,
  onFetchRows,
  rowsTotal,
  rows,
  apiRef,
  loading,
  onSortingChanged,
  sortModel,
  onCellClicked,
  selectableRows,
  onColumnOrderChange,
}: Props) => {
  return (
    <Stack
      position="relative"
      flex={1}
      onDrop={stopEventPropagation} //This workaround is related to this bug: https://github.com/react-dnd/react-dnd/issues/3491
    >
      <DataGrid<DrillDownRow>
        loading={loading}
        sortingMode="server"
        rowsLoadingMode="server"
        hideFooterPagination
        rowCount={rowsTotal}
        onFetchRows={onFetchRows}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onColumnOrderChange={onColumnOrderChange}
        onSortModelChange={onSortingChanged}
        onCellClick={onCellClicked}
        showColumnVerticalBorder
        rowHeight={32}
        columnHeaderHeight={32}
        selectableRows={selectableRows}
        slots={{
          loadingOverlay: LoadingOverlay,
        }}
        sx={getBiDataGridStyles}
        sortingOrder={["asc", "desc"]}
      />
    </Stack>
  );
};

export default DrillDownGrid;

const LoadingOverlay = () => {
  return <InlineLoader overlay />;
};
const stopEventPropagation = (e: React.SyntheticEvent) => {
  e.stopPropagation();
};
